import React, { Fragment, useState } from "react";
import { Disclosure, Popover } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AmsafLogo from "../static/icons/AMSAF_LOGO.png"
import { ChevronUpIcon } from '@heroicons/react/solid'
const Navbg = "https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/NavBg.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const divider = () => {
  return (
    <div className="relative flex py-1 items-center">
      <div className="flex-grow border-t border-gray-400"></div>
      <div className="flex-grow border-t border-gray-400"></div>
    </div>
  )
}
const dividerLight = () => {
  return (
    <div className="relative flex py-1 items-center">
      <div className="flex-grow border-t border-gray-300"></div>
      <div className="flex-grow border-t border-gray-300"></div>
    </div>
  )
}
const renderMobileNavbar = () => {
  return (
    <Disclosure.Panel className="top-14 overflow-y-scroll fixed w-[80%] h-[95vh] right-0 rounded-l-sm sm:hidden bg-[#f5f5f5] z-10 transform px-2 max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2 shadow-2xl drop-shadow-2xl ring-1 ring-black ring-opacity-5">
      <div className="grid px-2 pt-2 pb-3 space-y-1">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Our Services</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Why Us</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
            </>
          )}
        </Disclosure>
        {divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Our Story</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
            </>
          )}
        </Disclosure>{divider()}
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="text-[#3B3939] flex justify-between w-full px-4 py-2 text-lg font-medium text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                <span>Contact Us</span>
                <ChevronUpIcon
                  className={`${!open ? 'transform rotate-180' : ''
                    } w-5 h-5`}
                />
              </Disclosure.Button>
            </>
          )}
        </Disclosure>
      </div>
    </Disclosure.Panel>
  )
}
export default function Navbar({handleChangeSelectedNav}) {
  const [selectedNavOption, setSelectedNavOptions] = useState("");
  const [showAbout, setshowAbout] = useState(false);
  const [showProducts, setshowProducts] = useState(false);
  const [showServices, setshowServices] = useState(false);
  const handleMenuSetup = (menu) => {
    handleChangeSelectedNav(menu);
    setSelectedNavOptions(menu);
  }
  const renderAboutPopover = () => {
    return (
      <div className="relative">
        <div className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 text-md font-medium cursor-pointer"
        )}
          onClick={()=>{handleMenuSetup("ourServices")}}
        >
          Our Services
        </div>
        {selectedNavOption ==="ourServices" && <img src={Navbg} width={120} alt="" />}
      </div>
    )
  }
  const renderProductsPopover = () => {
    return (
      <Popover className="relative">
        <Popover.Button className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 text-md font-medium"
        )}
          onClick={()=>{handleMenuSetup("caterTo")}}
        >
          Why Us
        </Popover.Button>
        {selectedNavOption ==="carterTo" && <img src={Navbg} width={120} alt="" />}
      </Popover>
    )
  }
  const renderAboutUsPopover = () => {
    return (
      <div className="relative">
        <div className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 text-md font-medium cursor-pointer"
        )}
          onClick={()=>{handleMenuSetup("aboutUs")}}
        >
          About Us
        </div>
        {selectedNavOption ==="aboutUs" && <img src={Navbg} width={120} alt="" />}
      </div>
    )
  }
  const renderContactUsPopover = () => {
    return (
      <div className="relative">
        <div className={classNames(
          "text-[#3B3939] ",
          "px-3 py-2 text-md font-medium cursor-pointer"
        )}
          onClick={()=>{handleMenuSetup("footer")}}
        >
          Contact Us
        </div>
        {selectedNavOption ==="footer" && <img src={Navbg} width={120} alt="" />}
      </div>
    )
  }
  const toggleAllNestedNavsClose = () => {
    if (showAbout || showProducts || showServices) {
      setshowAbout(false);
      setshowProducts(false);
      setshowServices(false);
    }
  }

  return (
    <div style={{backgroundImage: "url("+Navbg+")", backgroundRepeat: 'no-repeat'}} className={`fixed grid w-[100%] h-fit max-w-auto z-10 drop-shadow-xl font-poppins bg-cover bg-center safari_only`} id="header">
      <div className="flex-1 max-w-auto pb-2 3xl:justify-self-center">
        <Disclosure>
          {
            ({ open }) => (
              <div className="w-auto flex items-center">
                <div className="flex items-center justify-start float-left pl-2 w-1/4">
                    <img
                      className="block h-12 w-auto ml-0 pt-2 sm:h-24"
                      src={AmsafLogo}
                      alt="amsaf"
                      width={100}
                    />
                </div>
                <div className="w-3/4">
                  <div className="hidden sm:flex justify-end items-end mr-0">
                    <div className=" flex mr:0 sm:space-x-2 md:space-x-2">
                      {/* navbar menu for desktop */}
                      {renderAboutPopover()}
                      {/* {renderProductsPopover()} */}
                      {renderAboutUsPopover()}
                      {renderContactUsPopover()}
                    </div>
                  </div>
                </div>
                {/* <div className=" inset-y-0 pr-2 flex items-center sm:hidden align-self-center">
                  <div className="pt-2 justify-end items-center z-10">
                    <Disclosure.Button className=" rounded-md text-gray-400 hover:text-white  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" stroke="black" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" stroke="black" />
                      )}
                    </Disclosure.Button>
                    {renderMobileNavbar()}
                  </div>
                </div> */}
              </div>
            )
          }
        </Disclosure>
      </div>
    </div>
  );
}