import React, { Fragment, useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from '@heroicons/react/solid';
import { footerNavs, connectWithUs, downloadApp } from "./Constants";
import axios from "axios";
import appConfig from "../config";
import amsaflogo from "../static/icons/AMSAF_LOGO.png";
import callicon from "../static/images/call_24px.svg";
import emailicon from "../static/images/email_24px.svg";
import locationicon from "../static/images/location_on_24px.svg";
const logo = amsaflogo;
const certifications = "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/certifications.webp";
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
const divider = () => {
    return (
        <div className="relative flex py-5 px-auto items-center pr-4">
            <div className="flex-grow border-t border-gray-400"></div>
            <div className="flex-grow border-t border-gray-400"></div>
        </div>
    )
}
const loadSpinner = () => {
    return (
        <svg role="status" className="right-0 w-4 h-4 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
    )
}
const checkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
        </svg>
    )
}
export default function Footer() {
    const [currentYear, setCurrentYear] = useState("");
    const [newsLetterEmail, setnewsLetterEmail] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [isSubscribed, setisSubscribed] = useState(false);

    useEffect(() => {
        setCurrentYear(new Date().getFullYear());
    }, []);

    const renderMobileFooterLinks = () => {
        return (
            <div className="">
                {
                    footerNavs.map((val, indx) => (
                        <Disclosure key={`${val.title}_${indx}`}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="text-white flex justify-between w-[95%] text-md font-semibold text-left rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                        <span>{val.title}</span>
                                        <ChevronUpIcon
                                            className={`${!open ? 'transform rotate-180' : ''
                                                } w-5 h-5`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pl-1 pt-4 pb-2 text-white">
                                        {val.arrLink.map((item, index) => (
                                            <Disclosure.Button
                                                key={item.link}
                                                as="a"
                                                href={item.href}
                                                target={item.target}
                                                className={classNames(
                                                    "text-white hover:cursor-pointer",
                                                    "block rounded-md text-md font-normal p-1 pt-2"
                                                )}
                                            >
                                                {item.link}
                                            </Disclosure.Button>
                                        ))}
                                    </Disclosure.Panel>
                                    {divider()}
                                </>
                            )}
                        </Disclosure>
                    ))
                }
            </div>
        )
    }
    const handleNewsletterSubscribe = async () => {
        const re = new RegExp("^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$");
        if (newsLetterEmail !== "" && !isSubscribed && re.test(newsLetterEmail)) {
            setisLoading(true);
            const res = await axios({
                method: "post",
                url: `${appConfig.domain[appConfig.environment]["agribazaar"]}/agribazaarSubscriber.php?email=${newsLetterEmail}`
            })
            if (res.data?.status && res.data.msg === "Successfully Submitted") {
                setisLoading(false);
                setisSubscribed(true);
                setTimeout(() => setnewsLetterEmail(""), 2000);
            } else if (res.data?.msg === "Duplicate entry"){
                alert("You've already subscribed for our newsletters!");
                setTimeout(() => setnewsLetterEmail(""), 0);
            } else {
                alert("error subscribing, please try again later.");
            }
            setisLoading(false);
        } else {
            alert("incorrect email");
        }
    }
    return (
        <Fragment>
            <footer id="footer" className="bg-[black] text-white footer pl-0 sm:pl-0 pt-10 pb-10 flex flex-col m-auto w-[100%] font-poppins">
                {/* desktop start */}
                <div id="wrapper-1" className="hidden sm:flex justify-center pl-8 self-center">
                    <div id="aboutAgribazaar" className="hidden pt-0 border-r-[0.5px] w-1/3 sm:flex flex-col items-center">
                        <div className="flex items-center justify-start text-left w-[65%]">
                            <img src={logo} width={"40%"} alt="amsaf" />
                        </div>
                        <div className="pl-3.5 w-[65%]">
                            <p className="flex items-start"><img src={locationicon} alt="." />Shiriki House <br />
                                Plot 16 Mackinnon Rd,<br />
                                P.O Box 2344 Kampala, Uganda.
                            </p>
                            {/* <p className="flex items-start pt-4"><img src={callicon} alt="." />&nbsp;+91 XXX XXX XXXX</p> */}
                            <p className="flex items-start pt-4"><img src={emailicon} alt="." />&nbsp;info@amsaf.africa</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-evenly w-2/3">        
                    {
                        footerNavs.map((val, indx) => (
                            <div key={`${val.title}_${indx}`} id="quick_links" className=" text-lg font-medium text-left w-[20%] max-w-1/6 pl-8">
                                {val.title}
                                <div>
                                    {val.arrLink.map((item, index) => (
                                        <div
                                            key={`${item.route}_${index}`}
                                            onClick={() => window.open(item.href, item.target)}
                                        >
                                            <p className="w-fit text-sm cursor-pointer pt-2 hover:text-slate-300"><b>{item.link}</b></p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))
                    }
                        <div id="connect_with_us" className="text-md font-medium text-center min-w-max max-w-1/6 pr-8">
                            <div className="pt-2 flex w-80">
                                <input value={newsLetterEmail}
                                    onChange={(e) => { return false }}
                                    className="w-full text-xs appearance-none block bg-gray-50 text-gray-700 border border-gray-200 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white" id="email" type="email" pattern="^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$" title="Enter a valid email id" placeholder="Enter your email address" />
                                <button
                                    onClick={() => { return false }}
                                    className="w-max max-w-min ml-2 whitespace-nowrap inline-flex justify-center p-2 m-auto border border-transparent rounded-md shadow-sm text-sm text-white bg-red-600 hover:bg-red-700">
                                    {isSubscribed ? <div className="flex">
                                        Submited {checkIcon()}
                                    </div> : 'Submit'} &nbsp; {isLoading && loadSpinner()}
                                </button>
                            </div>
                            <div className="flex m-2 justify-end">
                                {connectWithUs.map((item, index) => (
                                    <div key={index} className="rounded-8"
                                        onClick={() => ""}
                                    >
                                        <img src={item.imgUrl} className="cursor-pointer w-8 m-2" alt="connectWithUs" />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
                {/* desktop end */}
                {/* mobile start */}
                <div className="w-full flex flex-col pb-0 sm:hidden">
                    {/* <div className="pl-4 pb-0 w-full">
                        {renderMobileFooterLinks()}
                    </div> */}
                    <div id="connect_with_us" className="pl-4 font-bold text-left min-w-max max-w-1/6 pr-8 pb-0 ">
                        <div className="pt-2 flex w-80">
                            <input value={newsLetterEmail}
                                onChange={(e) => {return false}}
                                className="w-full text-xs appearance-none block bg-gray-50 text-gray-700 border border-gray-200 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white" id="email" type="email" pattern="^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$" title="Enter a valid email id" placeholder="Enter your email address" />
                            <button
                                onClick={()=>{return false}}
                                className="w-max max-w-min ml-2 whitespace-nowrap inline-flex justify-center p-2 m-auto border border-transparent rounded-md shadow-sm text-sm text-white bg-red-600 hover:bg-red-700">
                                {isSubscribed ? <div className="flex">
                                    Submitted {checkIcon()}
                                </div> : 'Submit'} &nbsp; {isLoading && loadSpinner()}
                            </button>
                        </div>
                        <div className="flex pt-2">
                            {connectWithUs.map((item, index) => (
                                <div key={index}
                                    onClick={() => ""}
                                >
                                    <img src={item.imgUrl} className="cursor-pointer w-8 pr-2" alt="connectWithUs" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* mobile end */}
                {/* <div className="w-full flex-col-reverse sm:flex-row flex justify-between pl-0 sm:pl-4 pr-4">
                    <div className="text-md font-medium text-left sm:text-center pl-4 pt-4 sm:pt-0">
                        SECURITY CERTIFICATIONS
                        <img src={certifications} className="w-48 pt-2 sm:pt-2" alt="certifications" />
                    </div>
                    <div className="pl-4 text-md font-medium text-left sm:text-start pt-4 sm:pt-0">
                        Sign up for our newsletter
                        <div className="pt-2 flex w-80">
                            <input value={newsLetterEmail}
                                onChange={(e) => setnewsLetterEmail(e.target.value)}
                                className="w-full text-xs appearance-none block bg-gray-50 text-gray-700 border border-gray-200 rounded py-1 px-2 leading-tight focus:outline-none focus:bg-white" id="email" type="email" pattern="^[a-zA-Z0-9_.]+[@]{1}[a-z0-9]+[\.][a-z]+$" title="Enter a valid email id" placeholder="E-mail" />
                            <button
                                onClick={handleNewsletterSubscribe}
                                className="w-max max-w-min ml-2 whitespace-nowrap inline-flex justify-center p-2 m-auto border border-transparent rounded-md shadow-sm text-sm text-white bg-red-600 hover:bg-red-700">
                                {isSubscribed ? <div className="flex">
                                    Subscribed {checkIcon()}
                                </div> : 'Subscribe'} &nbsp; {isLoading && loadSpinner()}
                            </button>
                        </div>
                    </div>
                    <div className="text-left sm:text-center pl-4 text-md font-medium">
                        DOWNLOAD AGRIBAZAAR APP
                        <div className=" flex m-0 sm:m-2">
                            {downloadApp.map((item, index) => (
                                <div key={index}
                                    className="cursor-pointer w-28 pt-2 sm:pt-0"
                                    onClick={() => window.open(item.route, "_blank")}
                                >
                                    <img src={item.imgUrl} className="pr-2" alt="downloadApp" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}

                {/* <div className="w-full items-center">
                    <hr className="bg-white w-[90%] mb-0 pb-[0.1rem] place-self-center" />
                </div>
                <div id="wrapper-3" className="h-0 text-center sm:flex w-[100%] flex items-center justify-center">
                    <div id="copyright" className="pl-16 pr-16 text-sm sm:text-lg">
                        © {currentYear} Star Agribazaar Technology Private Limited. All rights reserved
                    </div>
                </div> */}
            </footer>
        </Fragment>
    );
}