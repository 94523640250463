import React, { Fragment, useEffect } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import { Link } from 'react-router-dom';
import { servicesList, ourServices, caterToList } from "../components/Constants";
// import MobBanner from '../components/MobBanner';
import Carousel from 'better-react-carousel';
import agribazaarLogo from "../static/icons/agriHeadLogo.svg";
import staragriLogo from "../static/icons/staragri_logo.png";
import amsafLogo from "../static/icons/AMSAF_LOGO.png";
import asiliLogo from "../static/icons/asili.png";

// const banner1 = "https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/MainBanner1.png";
const banner2 = "https://amsaf-marketplace.s3.af-south-1.amazonaws.com/Production/MainBanner2adev.png";


export default function Home({ selectedNavOption }) {
  useEffect(() => {
    const element = document.getElementById(selectedNavOption);
    if (element) {

      // 👇 Will scroll smoothly to the top of the next section
      //element.scrollIntoView({ behavior: 'smooth', block: 'start', });

      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }, [selectedNavOption]);
  const RenderImageSliders = () => {
    return (
      <div className="w-[100%] h-[100%]">
        <div className='hidden sm:block md:flex lg:grid' >
          <SimpleImageSlider
            width={"100%"}
            height={"68vh"}
            images={[banner2]}
            showBullets={false}
            autoPlay={false}
            slideDuration={2}
            autoPlayDelay={4}
          />
        </div>
        <div className='sm:hidden '>
          <SimpleImageSlider
            width={"100%"}
            height={204}
            images={[banner2]}
            showBullets={false}
            autoPlay={false}
            slideDuration={2}
            autoPlayDelay={4}
          />
        </div>
      </div>
    )
  }
  const RenderOurServices = () => {
    return (
      <Fragment>
        <div id="ourServices-container"
          className="bg-white sm:block w-[100%] pt-4 sm:pt-8 pb-8 h-full flex-col bg-no-repeat bg-contain">
          <div id="wrapper-left" className="lg:w-full sm:pl-10 text-center sm:text-left pl-2 pr-2 sm:w-4/12 h-full ">
            <div className='pt-0 pr-14 pl-14 sm:pr-2 sm:pl-0 text-gray-500 flex justify-center sm:pt-2 sm:w-full'>
              <p className='hidden sm:block text-[#3B3939] text-center text-md w-[80%]'>At AMSAF, we are committed  to growth and success for agri stakeholders through an efficient marketplace, offering quality produce
                and equitable access to markets for all. Secure your future with a trusted partner by your side.</p>
            </div>
            <div id='ourServices' className="tracking-wide pt-4 sm:pt-8 text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex items-center font-bold">
              <p className='text-[black] leading-tight m-auto'>Our <span className='text-[#30A7D9] text-600'> Services</span></p>

            </div>
            <div className='pt-0 pr-14 pl-14 sm:pr-2 sm:pl-0 text-gray-500 flex justify-center sm:pt-2 sm:w-full'>
              <p className='hidden sm:block text-[#3B3939] text-center text-md w-[80%]'>AMSAF is a platform for agriculture trading and financing in Africa.We assure quality produce,timely fulfilment and secure transactions for stakeholders across the agri value chain. As a trusted partner,we provide :</p>
            </div>
          </div>
          <div id="wrapper-right" className="item-center lg:w-[100%] sm:w-8/12 sm:pl-8 sm:pr-16 bg-no-repeat">
            <div className=" sm:flex justify-center items-start  w-auto">
              <div id="indi-stories" className=" pb-2 pt-2 flex flex-row sm:pt-6 sm:pb-8">
                <div id="services">
                  <Carousel cols={3} rows={1} gap={3} loop
                    arrowLeft={
                      <div
                        className={`absolute top-0 bottom-0 left-0 flex justify-center items-center p-3 cursor-pointer text-[60px] -ml-6`}
                      >{"<"}
                      </div>}
                    arrowRight={
                      <div
                        className={`absolute top-0 bottom-0 right-0 flex justify-center items-center p-3 cursor-pointer text-[60px] -mr-6`}
                      >
                        {">"}
                      </div>}
                  >
                    {ourServices.map((item, index) =>
                      <Carousel.Item key={index}>
                        <Link to={item.route} target={item.route == "#" ? '_self' : '_blank'} className='block w-[100vw] sm:w-full'>
                          <div className='m-4 bg-[#e7e3e1] 2xl:h-60 h-72' key={index}>
                            <div className={` p-6 sm:p-2  2xl:grid  px-12 rounded-[8px] flex-col items-center `}>
                              <div className='my-6 2xl:w-[30%] w-full justify-self-center'>
                                <img src={item.icon} alt={item.title} width={"30%"} className=' m-auto' />
                              </div>
                              <h3 className='font-bold text-center text-lg my-2 text-[black]'>{item.title}</h3>
                              <p className='text-center text-sm my-4 text-[black]'>{item.content}</p>
                            </div>
                          </div>
                        </Link>
                      </Carousel.Item>
                    )}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  const RenderCaterTo = () => {
    return (
      <Fragment>
        <div id="caterTo"
          className="bg-[#dae2f0] sm:flex w-[100%] pt-4 sm:pt-8 pb-8 h-full flex-col bg-no-repeat bg-contain">
          <div id="wrapper-left" className="lg:w-full sm:pl-10 text-center sm:text-left pt-8 sm:pt-6 pl-2 pr-2 sm:w-4/12 h-full ">
            <div className="tracking-wide text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex items-center font-bold">
              <p className='text-[black] leading-tight m-auto'>Whom <span className='text-[#30A7D9] text-600'> we cater to?</span></p>

            </div>
          </div>
          <div className=" grid-cols-1 sm:grid sm:grid-cols-4 gap-0 align-baseline h-full w-[90%] mt-4 mx-auto my-auto">
            {caterToList.map((item, index) => (
              <div key={index}
                style={{ backgroundImage: 'url(' + item.image + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
                className={`text-center sm:flex sm:text-left items-center justify-center text-white bg-gradient-to-t from-[#4CAF50] bg-opacity-70 hover:from-[#4caf5075] ${index !== servicesList.length - 1 && 'mr-[0.1rem]'} `}
              >
                <div
                  id="services-items"
                  className="h-[100%] pt-64 w-[100%] rounded-b-md">
                  <div className='contents flex-col justify-end align-baseline p-5 h-inherit'>
                    <div className='h-[60%]'>
                      <p className='items-start p-4 text-md font-medium fading-div'>{item.title}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fragment>
    )
  }
  const RenderOurStories = () => {
    return (
      <Fragment>
        <div id="ourStory" className="bg-[#e7e3e1] sm:flex w-[100%] pt-12 sm:pt-0 p-0 h-full items-center justify-center pb-0 sm:pb-8">
          <div id="wrapper-left" className="lg:w-[80%] grid sm:pl-10 text-center sm:text-left pt-8 sm:pt-6 pl-2 pr-2 sm:w-full h-full ">
            <div className="tracking-wide text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex items-center font-bold">
              <p className='text-[black] leading-tight m-auto'>Our <span className='text-[#30A7D9] text-600'> Story</span></p>
            </div>
            <div className='pt-0 pr-7 pl-7 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-6'>
              <p className='sm:block text-[#3B3939] text-justify sm:text-center text-md my-2'>AMSAF is an extension of agribazaar in India. Since its inception in 2016, agribazaar has been a transformative force in Indian agriculture. It began with the launch of e-Mandi—a digital platform for trading agricultural commodities designed to bring transparency to market transactions.</p>
            </div>
            <div className="pt-0 flex pr-14 pl-14 sm:pr-2 sm:pl-0 w-full justify-center items-center sm:pt-6">
              <div className='w-2/4 sm:w-1/4 flex justify-end items-center'>
                <img src={agribazaarLogo} alt="Agribazaar" width={200} />
              </div>
              <div className="w-[5%]"></div>
              <div className='w-1/4 flex items-center mr-8'>
                <img src={staragriLogo} alt="StarAgri" width={200} />
              </div>
            </div>
            <div className='pt-6 text-center text-[#3B3939]'>
              <p><b>Over time, agribazaar expanded to include a variety of services and technologies:</b></p>
            </div>
            <div id="wrapper" className="grid-cols-1 grid sm:grid-cols-2 gap-0 align-baseline sm:text-left text-center pt-6 justify-self-center">
              <div className='block sm:flex'>
                <li className='text-[#30A7D9] w-[100%] sm:w-[40%]'><span>Input Marketplace:</span></li>
                <div className='text-[#3B3939] w-[100%] sm:w-[60%] pl-[1.3rem] sm:mb-0 mb-[0.5rem]'>Provides quality farm inputs.</div>
              </div>
              <div className='block sm:flex'>
                <li className='text-[#30A7D9]  w-[100%] sm:w-[25%]'><span>Agri Bhumi:</span></li>
                <div className='text-[#3B3939] w-[100%] sm:w-[70%] pl-[1.3rem] sm:mb-0 mb-[0.5rem]'>Offers satellite-based farm management services.</div>
              </div>
              <div className='block sm:flex'>
                <li className='text-[#30A7D9] w-[100%] sm:w-[40%]'><span>Output Marketplace:</span></li>
                <div className='text-[#3B3939] w-[100%] sm:w-[60%] pl-[1.3rem] sm:mb-0 mb-[0.5rem]'>Facilitates the selling of produce.</div>
              </div>
              <div className='block sm:flex'>
                <li className='text-[#30A7D9] w-[100%] sm:w-[25%]'><span>Agri Pay:</span></li>
                <div className='text-[#3B3939] w-[100%] sm:w-[70%] pl-[1.3rem] sm:mb-0 mb-[0.5rem]'>Ensures secure payments.</div>
              </div>
            </div>
            <div className='pt-0 pr-7 pl-7 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-8'>
              <p className='sm:block text-[#3B3939] text-justify sm:text-center text-md sm:mb-0 mb-[0.5rem]'>This robust ecosystem has vastly enhanced efficiency and productivity in the Indian agricultural sector. AMSAF, as an expansion of agribazaar, aims to replicate this successful model in East Africa. The goal is to establish a structured, reliable agricultural ecosystem, instilling trust in the market and creating momentum in the region.</p>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }


  const RenderAboutUs = () => {
    return (
      <Fragment>
        <div id="aboutUs" className="bg-[#e7e3e1] sm:flex w-[100%] pt-12 sm:pt-0 p-0 h-full items-center justify-center pb-0 sm:pb-8">
          <div id="wrapper-left" className="lg:w-[80%] grid sm:pl-10 text-center sm:text-left pt-8 sm:pt-6 pl-2 pr-2 sm:w-full h-full ">
            <div className="tracking-wide text-3xl sm:text-5xl contents max-w-[100%] w-auto sm:flex items-center font-bold">
              <p className='text-[black] leading-tight m-auto'>About <span className='text-[#30A7D9] text-600'> Us</span></p>
            </div>

            <div className='pt-0 pr-7 pl-7 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-6'>
              <p className='sm:block text-[#3B3939] text-justify sm:text-center text-md my-2'>AMSAF is an extension of agribazaar in India. Since its inception in 2016, agribazaar has been a transformative force in Indian agriculture. It began with the launch of e-Mandi—a digital platform for trading agricultural commodities designed to bring transparency to market transactions.</p>
            </div>
            
            <div className='pt-0 pr-7 pl-7 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-6'>
              <p className='sm:block text-[#3B3939] text-justify sm:text-center text-md my-2'>AMSAF is also a product of Asili,an integrated food security enterprise in East Africa.Established in 2012,Asili is a leading producer,aggregator, and service provider in the region,lending its large network of farmers and agri-communities to AMSAF.</p>
            </div>
           
            <div className='pt-0 pr-7 pl-7 sm:pr-2 sm:pl-0 text-gray-500 flex flex-col justify-start sm:pt-8'>
              <p className='sm:block text-[#3B3939] text-justify sm:text-center text-md sm:mb-0 mb-[0.5rem]'>This robust ecosystem has vastly enhanced efficiency and productivity in the Indian agricultural sector. AMSAF, as an expansion of agribazaar, aims to replicate this successful model in East Africa. The goal is to establish a structured, reliable agricultural ecosystem, instilling trust in the market and creating momentum in the region.</p>
            </div>

            <div className="pt-0 flex pr-14 pl-14 sm:pr-2 sm:pl-0 w-full justify-evenly items-center sm:pt-6 sm:mb-0 mb-[0.5rem]">
              <div className='sm:w-[20%] w-[28%] flex items-center justify-center'>
                <img src={agribazaarLogo} alt="Agribazaar" width={200} />
              </div>
              <div className="font-bold flex items-center justify-center w-[3%] sm:w-[5%] sm:text-[1rem] text-[10px] px-2 sm:px-0">By</div>
              <div className='sm:w-[20%] w-[18%] flex items-center justify-center'>
                <img src={staragriLogo} alt="StarAgri" width={150} className='pb-[5px]' />
              </div>
              <div className="font-bold flex items-center justify-center w-[3%] sm:w-[5%] sm:text-[1rem] text-[10px]">+</div>
              <div className='sm:w-[20%] w-[18%] flex items-center justify-center'>
                <img src={asiliLogo} alt="Asili" width={150} />
              </div>
              <div className="font-bold flex items-center justify-center w-[3%] sm:w-[5%] sm:text-[1rem] text-[10px]">=</div>
              <div className='sm:w-[20%] w-[18%] flex items-center justify-center'>
                <img src={amsafLogo} alt="Amsaf" width={180} />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <div
      className='p-0 overflow-hidden pt-20 sm:pt-24 z-0  m-auto w-[100%] font-poppins'>
      {RenderImageSliders()}
      {RenderOurServices()}
      {RenderAboutUs()}
    </div>
  )
}
