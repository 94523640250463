import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';

export default function DiscloseWTextSubMenu({ title, content, handleTitleClick, handleTitleClickMob, isDefaultOpen = false}) {
    return (
        <div className="w-full px-2 pb-6">
            <div className="p-2 mx-auto  bg-white border-t-2 border-[#F7D39D]">
                <Disclosure as="div" className="mt-2" defaultOpen={isDefaultOpen} >
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="items-center flex justify-between w-full px-2 pt-2 pb-0 text-sm font-medium text-left text-[#3B3939] rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                <div className='flex flex-row items-center place-items-center text-lg text-start p-0 gap-4 w-[90%] h-6 sm:h-4'>
                                    <span className='hidden sm:block items-start text-md font-semibold pl-0 hover:underline' onClick={() => handleTitleClick()}>{title}</span>
                                    <span className='items-start text-md font-semibold pl-0 hover:underline sm:hidden' onClick={() => handleTitleClickMob()}>{title}</span>
                                </div>
                                {content !== false && (<ChevronUpIcon
                                    className={`${!open ? 'transform rotate-180' : ''
                                        } w-5 h-5 text-[#3B3939]`}
                                />)}
                            </Disclosure.Button>
                            {content !== false && (<Disclosure.Panel className="sm:px-0 pt-4 pb-2 text-left text-md text-[#3B3939]">
                                {content}
                            </Disclosure.Panel>)}
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    );
}

