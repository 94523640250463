const appConfig = {
  environment: "PRODUCTION",
  domain: {
    PRODUCTION: {
      agribazaar: "https://api.agribazaar.com",
      blog: "https://blog.agribazaar.com",
      noderefresh: "https://api2.agribazaar.com",
    },
    UAT: {
      agribazaar: "http://apiuat.agribazaar.com",
      blog: "http://bloguat.agribazaar.com",
      noderefresh: "http://api2uat.agribazaar.com",
    },
  },
  route: {
    PRODUCTION: "https://www.amsaf.africa",
    UAT: "https://dev.amsaf.africa"
  },
  farmerScoreCardRoute: {
    PRODUCTION: "https://finance.agribazaar.com/",
    UAT: "https://financedev.agribazaar.com/"
  },
  marketPlace: {
    PRODUCTION: "https://marketplace.agribazaar.com/",
    UAT: "https://uatmarketplace.agribazaar.com/"
  },
  blog: {
    PRODUCTION: "https://blog.agribazaar.com",
    UAT: "https://bloguat.agribazaar.com"
  },
  ourServices:{
    marketplace:{
      PRODUCTION:"https://marketplace.amsaf.africa/auth/login",
      UAT:"https://marketplaceuat.amsaf.africa/auth/login"
    }
  }
};

export default appConfig;
