import appConfig from "../config";
//about team members
import aboutAmit from "../components/AboutOurTeam/AmitAg";
import aboutAmitMun from "../components/AboutOurTeam/AmitMun";
import aboutAnup from "../components/AboutOurTeam/AnupSingh";
import aboutAtul from "../components/AboutOurTeam/AtulChh";
import aboutAmitKh from "../components/AboutOurTeam/AmitKh";

import agribhumisvg from "../static/images/homepage/agribhumi.svg";
import imageTest from "../static/images/homepage/imgtest.jpg";
import trader from "../static/images/trader-new.jpg";
import millers from "../static/images/millersAndProcessor.jpg";
import farmer from "../static/images/farmer.jpg";
import financialImg from "../static/images/financial-institution.jpg"
import ourservice1 from "../static/images/ourservice1.png"
import ourservice2 from "../static/images/ourservice2.png"
import ourservice3 from "../static/images/ourservice3Update.png"
import twitter from "../static/images/homepage/twitter.png"
import facebook from "../static/images/homepage/facebook.png"
import instagram from "../static/images/homepage/youtube.png"
import linkedIn from "../static/images/homepage/linkedin.png"
//header
export const partnerWithUsRoute = { link: `${appConfig.route[appConfig.environment]}/partnerwithus`, target: "_self" };
export const createYourTradeRoute = { link: `${appConfig.route[appConfig.environment]}/createtrade`, target: "_self" };
export const registerRoute = { link: `${appConfig.route[appConfig.environment]}/login`, target: "_blank" };
export const loginBtnRoute = { link: `${appConfig.route[appConfig.environment]}/login`, target: "" };
export const farmerScoreCardloginBtnRoute = { link: `${appConfig.farmerScoreCardRoute[appConfig.environment]}`, target: "" };

const ourServicesRoutes=appConfig.ourServices.marketplace[appConfig.environment];
const inputMarketplaceNewRouteUrl = appConfig.marketPlace[appConfig.environment];

export const aboutAgribazaarNavigation = [
    { name: "Founder's Message", href: "/founder-message", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/foundersMessage.svg" },
    { name: "Our Team", href: "/our-team", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourTeam.svg" },
    { name: "Our Vision", href: "/our-vision", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourVision.svg" },
    { name: "Our Story", href: "/our-story", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/navbar/ourStory.svg" },
];
export const ourProductsNavigation = [
    { name: "AgriBhumi", href: "/our-products/agribhumi", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi.svg" },
    {
        name: "Input Marketplace",
        href: inputMarketplaceNewRouteUrl,
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace.svg"
    },
    { name: "Online Auction Engine", href: "/our-products/online-auction-engine", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine.svg" },
    { name: "e-Mandi", href: "/our-products/e-mandi", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi.svg" },
    { name: "AgriKnow (GAP)", href: "/our-products/agriknow", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow.svg" },
    { name: "AgriPay", href: "/our-products/agripay", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay.svg" },
    { name: "Marketplace", href: "/our-products/marketplace", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace.svg" },
    { name: "Kisan Safalta Card", href: "/our-products/kisan-credit-card", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc.svg" },
];
export const ourServicesNavigation = [
    { name: "IoT Based Farm Management", href: "/our-services/iot-based-farm-management", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/farmManagement.svg" },
    { name: "Crop Assessment using AI, ML, GIS", href: "/our-services/crop-assessment", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/cropAssesment.svg" },
    { name: "Intelligent Goods & Settlement Services", href: "/our-services/goods-and-settlement", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/intelligentGoods.svg" },
    { name: "Input & Output Marketplace", href: "/our-services/input-output-marketplace", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/inputOutputMarketplace.svg" },
    { name: "Agri Fintech Services", href: "/our-services/agri-fintech-services", imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/agriFintech.svg" },
    { name: "Farmer Scorecard Services", href: "/our-services/farmer-scorecard-service", imgSrc: "https://abazaar.s3.ap-south-1.amazonaws.com/images/ourServices/farm_report_requests.svg" }
];
export const staticNavigation = [
    {
        name: "Careers",
        // href: "/careers/career-opportunities"
        href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    },
    {
        name: "Contact us",
        href: "/contact-us"
    },
];
//home page
export const backgroundSVGs = {
    "ourServicesBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageServices/ourServices-bg.svg",
    "whatPeopleSayAboutAgribazaarBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/peopleSayAboutAgribazaar-bg.svg",
    "theBazaarStoriesBG": "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageBazaarStories/theBazaarStories-bg.png"
};
export const productList = [
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/input-marketplace-white.svg",
        name: "Input Marketplace",
        content: "500+ best quality farm inputs to support India grow better, faster and healthier!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/inputMarketplace.svg",
        route: inputMarketplaceNewRouteUrl,
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/input-marketplace_blk.svg",
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agribhumi-white.svg",
        name: "AgriBhumi",
        content: "Power of Satellite Imagery and Machine Learning taking Indian Agriculture ecosystem to new frontiers.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agribhumi.svg",
        route: "our-products/agribhumi",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agribhumi_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/auction-engine-white.svg",
        name: "Online Auction Engine",
        content: "State-of-art online auction mechanism that gained trust of 2 lakh + traders, FPOs, corporates!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/onlineAuction.svg",
        route: "our-products/online-auction-engine",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/auction-engine_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/e-mandi-white.svg",
        name: "e-Mandi",
        content: "10,000+ Happy Farmers getting the best prices for their produce.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/eMandi.svg",
        route: "our-products/e-mandi",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/e-mandi_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agriknow-white.svg",
        name: "AgriKnow (GAP)",
        content: "India’s emerging crop advisory platform for 700+ agri commodities across country!",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agriknow.svg",
        route: "our-products/agriknow",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agriknow_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/agripay-white.svg",
        name: "AgriPay",
        content: "Transaction worth $1 billion USD! Our award-winning payment mechanism.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/agripay.svg",
        route: "our-products/agripay",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/agripay_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/marketplace-white.svg",
        name: "Marketplace",
        content: "With 1,00,000+ onboard users, we are India’s leading e-marketplace.",
        prodImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProducts/marketplace.svg",
        route: "our-products/marketplace",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/marketplace_blk.svg"
    },
    {
        imgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc.svg",
        whiteImgSrc: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepage/kcc-white.svg",
        name: "Kisan Safalta Card",
        content: "Kisan Safalta Card by agribazaar. A simple way to make your farming easier and better.",
        prodImgSrc: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageProducts/Group+13798.png",
        route: "our-products/kisan-credit-card",
        blackSvg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepageProductsBlack/kisanCC_blk.svg"
    },
];
export const servicesList = [
    {
        // imgSrc: ,
        name: "IoT Based Farm Management",
        content: "An easy-to-use farm management platform that assists agricultural stakeholders across the value chain.",
        route: "our-services/iot-based-farm-management",
    },
    {
        // imgSrc: ,/
        name: "Crop Assessment using AI, ML, GIS",
        content: "Transform your plantation with effective crop-level insights using the strength of AI, ML & so much more!",
        route: "our-services/crop-assessment",
    },
    {
        // imgSrc: ,
        name: "Intelligent goods & settlement services",
        content: "Smooth trading with simple & secure payments and dispute-free settlement mechanism.",
        route: "our-services/goods-and-settlement",
    },
    {
        // imgSrc: ,
        name: "Input & Output marketplace",
        content: "One-stop solution to buy inputs. Trade your quality crops at the best prices.",
        route: "our-services/input-output-marketplace",
    },
    {
        // imgSrc: ,
        name: "Agri fintech services",
        content: "Envisioning a world free of financial burden by democratizing lending credits for farmers.",
        route: "our-services/agri-fintech-services",
    },
];
export const testimonials = [
    {
        vidUrl: "https://youtu.be/LyLRlPM1ZEE",
        subHead: `“agribazaar has kept me updated with live fluctuations in mandi price and all current business updates.”`,
        name: " Suneel Kumar Agarwal",
        desig: "Trader"
    },
    {
        vidUrl: "https://youtu.be/BEHwbxmYfD8",
        subHead: `“Even during the pandemic, agribazaar has been working great and provided me convenience and ease in trading.”`,
        name: "Kamlesh Kumar Bansal",
        desig: "Trader"
    },
    {
        vidUrl: "https://youtu.be/zIzcxLyABaQ",
        subHead: `“agribazaar team has managed timely calls and ZOOM meetings to help me grow my business.”`,
        name: "Sanath Kumar Jain",
        desig: "Trader"
    }
];
export const bazaarStories = [
    {
        title: "Turning back to roots with Zero-Budget Natural Farming",
        content: "Finance Minister Nirmala Sitharaman thrust zero budget farming into the spotlight in the first budget speech, calling for a “back to the basics” approach.",
        date: "MAY 25, 2022",
        route: `${appConfig.blog[appConfig.environment]}/turning-back-to-roots-with-natural-farming/`
    },
    {
        title: "Vertical Farming in India",
        content: "Agriculture in India is often affected by soil degradation, desertification, and extreme weather conditions such as floods and droughts.",
        date: "APRIL 20, 2022",
        route: `${appConfig.blog[appConfig.environment]}/vertical-farming-in-india/`
    },
    {
        title: "Online Auction Engine by agribazaar",
        content: "It is a customized auction portal for corporates and traders to carry out trades, manage deliveries, and buy agricultural products from anywhere across the country.",
        date: "MARCH 31, 2022",
        route: `${appConfig.blog[appConfig.environment]}/online-auction-engine-by-agribazaar/`
    },
];

export const ourServices = [
    {
        title: "AMSAF Marketplace",
        content: "Digital platform for trading produce with vetted counter parties.",
        route: ourServicesRoutes,
        icon: ourservice2,
    },
    {
        title: "AMSAF Hubs",
        content: "Aggregation infrastructure network providing agriculture produce origination,quality assurance,fulfilment and export services.",
        route:"#",
        icon: ourservice1,
    },
    {
        title: "AMSAF Financing Solutions",
        content: "Access financing from empanelled financial institutions to grow your business.",
        route:"#",
        icon: ourservice3,
    },
];

export const caterToList = [
    {
        title: "Millers & Processors",
        image: millers,
    },
    {
        title: "Traders",
        image: trader,
    },
    {
        title: "Farmers",
        image: farmer,
    },
    {
        title: "Financial Institutions",
        image: financialImg,
    },
];



export const ourTeamLinks = [
    {
        title: "Our Team",
        href: "/our-team",
        target: "_self"
    },
    {
        // title: "Press Release",
        title: "Farm Management",
        // href: "/press-release",
        href: `${appConfig.blog[appConfig.environment]}/category/farm-management/`,
        target: "_blank"
    },
    {
        title: "Commodity Outlook",
        // href: "/commodity-outlook",
        href: `${appConfig.blog[appConfig.environment]}/category/commodity-outlook/`,
        target: "_blank"
    },
    // {
    //     title: "Farm Management",
    //     href: "https://blog.agribazaar.com/category/farm-management/",
    //     target: "_blank"
    // },
    {
        title: "Newsletters",
        // href: "/newsletters",
        href: `${appConfig.blog[appConfig.environment]}/category/agritalk/`,
        target: "_blank"
    },
];
export const ourTeamList = [
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/AmitMundawala.png",
        name: "Amit Mundawala",
        desig: "Co-Founder & Managing Director",
        text: aboutAmitMun,
        linkedInUrl: "https://www.linkedin.com/in/amit-mundawala-977b304b/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Amith_Agarwal.png",
        name: "Amith Agarwal",
        desig: "Co-Founder & CEO",
        text: aboutAmit,
        linkedInUrl: "https://www.linkedin.com/in/amith-agarwal-ab19848/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Amit_khandelwal.png",
        name: "Amit Khandelwal",
        desig: "Co-Founder & Director",
        text: aboutAmitKh,
        linkedInUrl: "https://in.linkedin.com/in/amit-khandelwal-75580823"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Anup_Singh.png",
        name: "Anup Singh",
        desig: "Chief Product & Technology Officer",
        text: aboutAnup,
        linkedInUrl: "https://www.linkedin.com/in/anup-singh-7193941/"
    },
    {
        imgUrl: "https://abazaar.s3.ap-south-1.amazonaws.com/images/homepageTeam/Atul_Chhura.png",
        name: "Atul Chhura",
        desig: "Chief Business Officer",
        text: aboutAtul,
        linkedInUrl: "https://www.linkedin.com/in/atulchhura"
    }
];
export const partners = [
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/nafed.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/britannia.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/adani.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/kirti.webp", 
    "https://abazaar.s3-ap-south-1.amazonaws.com/images/homepagePartners/cargill.webp"
];
export const theBazaarStoriesViewMoreBtnRoute = `${appConfig.blog[appConfig.environment]}/category/blog/`;
//footer
export const downloadApp = [
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/google-play.svg",
        route: "https://play.google.com/store/apps/details?id=com.agribazaarapp"
    },
    {
        imgUrl: "https://abazaar.s3-ap-south-1.amazonaws.com/images/footer/app-store.svg",
        route: "https://apps.apple.com/in/app/agribazaar/id1589192238"
    },
];
export const connectWithUs = [
    {
        imgUrl: facebook,
        route: "https://www.facebook.com/agribazaar.official/"
    },
    {
        imgUrl: linkedIn,
        route: "https://www.linkedin.com/company/25067493/"
    },
    {
        imgUrl: twitter,
        route: "https://twitter.com/AgribazaarA"
    },
    {
        imgUrl: instagram,
        route: "https://www.youtube.com/channel/UCu0K5EmaRgZfT0ZW55s8NdA/videos"
    },
];
export const quickLinks = [
    {
        name: "FAQs",
        link: "FAQs",
        href: "#",
        // href: `${appConfig.route[appConfig.environment]}/faq`,
        target: "_self"
    },
    {
        name: "Terms & Conditions",
        link: "Terms of Use",
        href: "#",
        href: `${appConfig.route[appConfig.environment]}/terms`,
        target: "_self"
    },
    {
        name: "Cookies/ Privacy Policy",
        link: "Cookies/ Privacy Policy",
        href: "#",
        target: "_self"
    },
];
export const agriInsights = [
    {
        name: "Blog",
        link: "Blog",
        // href: "/agri-insights/blog",
        href: `${appConfig.blog[appConfig.environment]}/category/blog/`,
        target: "_blank"
    },
    {
        name: "agriTalk Magazine",
        link: "agriTalk Magazine",
        // href: "/agri-insights/agriTalk",
        href: `${appConfig.blog[appConfig.environment]}/category/agritalk/`,
        target: "_blank"
    },
    {
        name: "agri Insights",
        link: "agri Insights",
        // href: "/agri-insights/agriInsights",
        href: "https://blog.agribazaar.com/category/commodity-outlook/",
        target: "_blank"
    },
    {
        name: "Sale of Nafed PSS Stock",
        link: "Sale of Nafed PSS Stock",
        // href: "/agri-insights/sale-of-nafed-pss",
        href: `${appConfig.blog[appConfig.environment]}/category/sale-of-nafed-pss-stock/`,
        target: "_blank"
    },
];
export const careers = [
    {
        name: "Why agribazaar",
        link: "Why agribazaar",
        href: "/careers/why-agribazaar",
        // href: `${appConfig.route[appConfig.environment]}/whyagribazaar`,
        target: "_self"
    },
    {
        name: "People Philosophy",
        link: "People Philosophy",
        href: "/careers/people-philosophy",
        // href: `${appConfig.route[appConfig.environment]}/peoplephilosophy`,
        target: "_self"
    },
    {
        name: "Career Opportunities",
        link: "Career Opportunities",
        // href: "/careers/career-opportunities",
        href: `${appConfig.route[appConfig.environment]}/careeropportunities`,
        target: "_self"
    },
    {
        name: "Join Us",
        link: "Join Us",
        href: "/contact-us",
        target: "_self"
        // href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    }
];
export const options = [
    {
        name: "Our Services",
        link: "Our Services",
        href: "#",
        target: "_self"
        // href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    },
    {
        name: "Our Story",
        link: "Our Story",
        href: "#",
        target: "_self"
        // href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    },
    {
        name: "Contact Us",
        link: "Contact Us",
        href: "#",
        target: "_self"
        // href: `${appConfig.route[appConfig.environment]}/careeropportunities`
    },
    {
        name: "Terms of Use",
        link: "Terms of Use",
        href: "/quick-links/privacy-policy",
        // href: `${appConfig.route[appConfig.environment]}/terms`,
        target: "_blank"
    },
]
export const footerNavs = [
    {
        title: "",
        arrLink: options
    },
    // {
    //     title: "Helpful Links",
    //     arrLink: quickLinks
    // },
];
export const contactUsList = {
    mumbaiOffcImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/contactUs/Mumbai-office.png",
    delhiOfficeImg: "https://abazaar.s3-ap-south-1.amazonaws.com/images/contactUs/Delhi-office.png"
};
//blogs and articles